// import Swiper JS
import Swiper from 'swiper';
import {Navigation} from 'swiper/modules';

// import Swiper styles
import 'swiper/css';

window.addEventListener('load', ()=> {
    initSlider();
});

window.addEventListener('orientationchange', ()=> {
    initSlider();
})

function initSlider() {
    const wrapper = document.querySelectorAll('.slider-with-icons-block');

    wrapper.forEach(element => {
        const swiperElement = element.querySelector('.swiper');
 
        new Swiper(swiperElement, {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 2,
    
            navigation: {
                nextEl: element.querySelector('.swiper-button-next'),
                prevEl: element.querySelector('.swiper-button-prev'),
            },
        });
    });
}
